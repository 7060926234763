
$(document).ready(function(){
    $(".MainRootSub .MainTitle").click(function() {
        if($(this).hasClass('ShowLine')) {
            $(this).addClass('HideLine').removeClass('ShowLine');
        }
        else{
           $(this).addClass('ShowLine').removeClass('HideLine');
        }
    });
});

$(document).ready(function(){
    $(".MainRootSub .MainTitle").click(function(){
        $(this).next('ul').slideToggle(500);
    });
});



